.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.formContainer {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  max-width: 2360px;
}
.topItems {
  margin-bottom: 0.5rem;
}
.spanContainer {
  margin-right: 1rem;
}
.spanHeading {
  line-height: 28px;
}
.spanHeadingAlignRight {
  text-align: right;
}
.headerRow {
  margin-top: 5px;
  margin-bottom: 5px;
}

.sendButton {
  width: 100px;
  margin-right: 0.5rem;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.emailAddrHeading {
  color: var(--cse-neg);
  margin-bottom: 0;
}
.sendImage {
  height: 48px;
  width: 48px;
}
.screenshotContainer {
  flex-direction: column;
}
.screenshotPreview {
  object-fit: contain;
  width: auto;
}
.screenshotShow {
  display: block;
  animation: fadeInAnimation 0.25s;
}
.screenshotHide {
  display: none;
  animation: fadeOutAnimation 0.25s;
}
.formTextInput {
  width: 60vw;
  color: var(--cse-text);
  background-color: var(--cse-bg);
  border: 1px solid var(--cse-bg-alt);
  border-radius: 0.25rem;
  max-width: 2100px;
}
.formTextInput:focus {
  border-color: var(--cse-primary);
}
.textArea {
  margin-top: auto;
  min-height: 350px;
  color: var(--cse-text);
  background-color: var(--cse-bg);
  border: 1px solid var(--cse-bg-alt);
}
.textArea:focus {
  border-color: var(--cse-primary);
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}
@keyframes fadeOutAnimation {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}
.emailSentText {
  text-align: center;
  font-size: 20px;
}

/* Schedule */
.scheduleHeader {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scheduleCard {
  margin: 1rem;
  margin-bottom: 1.5rem;
}
.scheduleExtra {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: left;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}
.scheduleExtraRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.scheduleExtraWidthFix {
  flex-grow: 0;
  margin-left: 0;
  margin-right: 0;
}
.scheduleExtraTextInput {
  color: var(--cse-text);
  background-color: var(--cse-bg);
  border: 1px solid var(--cse-bg-alt);
  border-radius: 0.25rem;
}
.scheduleExtraTextInput:focus {
  border-color: var(--cse-primary);
}

@media only screen and (max-height:700px) {
  .scheduleExtra {
    margin: 0;
  }
}