body{
  min-width: 614px;
}
.header-flex-center {
  display: flex;
  align-items: center;
}
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* max-width: 1880px; */
  margin-left: auto;
  margin-right: auto;
}
.header-container h3 {
  padding-right: 5px;
  margin-bottom: 0px;
  color: var(--cse-text);
  animation: fadeInAnimation 0.25s;
}
.header-item {
  flex: 1 0 31%
}
.header-item h3 {
  user-select: none;
  line-height: 45px;
}
.header-customer-id {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 15px;
  align-content: center;
}

.dash-container {
  /* max-width: 1880px; */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dash-card {
  width: 550px;
  height: 210px;
  text-align: center;
  margin: 5px;
  border: 1px solid var(--cse-card-border);
  border-radius: 3px 3px 12px 12px;
  box-shadow: 0.10em 0.30em 0.2em var(--cse-card-border);
  background-color: var(--cse-card-bg);
  min-width: 550px;
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: hidden;
}

.dash-half-w-card {
  width: 250px;
}

.dash-header {
  display: flex;
  align-content: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
}
.dash-header:hover {
  background-color: var(--cse-card-bg-alt);
  /*box-shadow: 0px 1px 5px 0px var(--cse-text-alt); */ /* I can't tell if this looks better or worse... */
}

.spinner-position {
  position: absolute;
  right: 10px;
  top: -48px;
}
.spinner-parent {
  position: relative;
}


.dash-half-w-card .spinner-border {
  position: absolute;
  top: -45px;
  right: 5px;
}

.dash-extra {
  animation: ease-in 1s;
  position: relative;
  top: -5;
  z-index: 25;
}

.dash-card.fixed-height {
  height: 220px;
}

.dash-card h2 {
  color: var(--cse-text);
  user-select:none;
  cursor: pointer;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  margin: 0 auto 0 auto;
}
.dash-card a:hover {
  color: var(--cse-text);
  text-decoration: none;
}
.draggable-ind {
  border-top: 4px solid var(--cse-text-alt);
  border-radius: 3px 3px 0px 0px;
}

.dash-half-w-card h2 {
  margin-bottom: 0px;
}

.dash-card .main {
  text-anchor: middle;
  font-size: 36px;
  white-space: pre;
  user-select:none;
}

.dash-pos {
  color: var(--cse-pos);
}
    .dash-pos-opt80 {
        color: var(--cse-pos);
        opacity: 0.8;
    }

.dash-neut {
  color: var(--cse-text);
}

.dash-neg {
  color: var(--cse-neg);
}

.dash-warn {
  color: var(--cse-warn);
}
.dash-card p.dash-xsmall-text {
  font-size: 17px;
}
p.dash-no-overflow {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 75px;
  overflow: hidden;
  white-space: nowrap;
}
.dash-card p.dash-max-w-70 {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dash-card .dash-small-text {
  font-size: 22px;
  text-transform: capitalize;
}
.dash-30-text {
  font-size: 30px !important;
}

.dash-card p {
  color: var(--cse-text);
  font-size: 20px;
  user-select:none;
  text-transform: capitalize;
}

.dash-content-row {
  align-items: center;
  height: 166.6px;
  padding-bottom: 0 !important; 
  /* CSS needed to move content back up during spinner animation
  trouble is this needs to be toggled on / off when spinner is removed
  position: relative;
  top: -50px; */
}
.dash-content-col {
  max-width: 274px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dash-content-col {
  max-height: 135px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dash-content-col-1 {
  max-width: 540px;
}
.dash-content-col-2 {
  max-width: 270px;
}
.dash-content-col-3 {
  max-width: 180px;
}
.dash-content-col-4 {
  max-width: 135px;
}
.dash-content-col-overflow {
  overflow: hidden;
}

.dash-graph {
  padding: 0px 20px !important;
}

.dash-login-graph {
  height: 151px;
  width: 342px !important;
}

.cse-icon {
  filter: var(--cse-icon-filter);
}
/*.perm-floating-label used for <select>*/
/* Colors */
select, select::after, select:hover{
  background-color: var(--cse-bg) !important;
  color: var(--cse-text) !important;
}
.perm-floating-label {
  position: relative;
  width: 100%;
}

.perm-floating-label select {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.perm-floating-label>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
  padding: var(--input-padding-y) var(--input-padding-x);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: var(--cse-text-alt);
}
.icon-container {
  height: 45px;
  width: 45px;
  padding: 5px;
  margin-left: 5px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  display: flex;
  justify-content: center;
  align-content: center;
}
.icon-container:active {
  border: 2px solid var(--cse-primary);
}
/* New Header Items */
.headerContainer {
  display: flex;
  margin-right: 25px;
  min-width: 25px;
  animation: fadeInAnimation 0.25s;
}
.clockIcon {
  position: relative;
  top: 8px;
  left: 33px;
  height: 30px;
  width: 30px;
  filter: var(--cse-icon-filter);
}
.headerFilterBy {
  display: block;
  height: 45px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: end;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .headerFilterBy {
    transition: none;
  }
}
.headerFilterBy::-ms-expand {
  background-color: transparent;
  border: 0;
}
.headerFilterBy:focus {
  color: #495057;
  background-color: #fff;
  border-color: #866ec9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(72, 50, 134, 0.25);
}
.headerFilterBy::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.headerFilterBy::placeholder {
  color: #6c757d;
  opacity: 1;
}
.headerFilterBy:disabled, .headerFilterBy[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.settingsIconContainer {
  height: 45px;
  width: 45px;
  padding: 5px;
  margin-left: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: pointer;
}
.helpIconContainer {
  display: flex;
  width: fit-content;
  height: 45px;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
}
.helpIcon ,.settingsIcon {
  filter: var(--cse-icon-filter);
  height: 100%;
  width: 100%;
}
.commentIcon {
  filter: var(--cse-icon-filter);
  position: relative;
  left: 266px;
  bottom: 26px;
  z-index: 20;
  cursor: pointer;
}

/* Larger than 1880px */
@media (min-width: 1961px) {
  .header-customer-id {
    padding-left: 105px;
  }
  .headerContainer {
    padding-right: 105px;
    margin-right: 0px;
  }
}
/* 3 columns */
@media (min-width: 1761px) and (max-width: 1960px) {
  .header-customer-id {
    padding-left: calc((100vw - 1680px - 80px) / 2);
  }
  .headerContainer {
    padding-right: calc((100vw - 1680px - 80px) / 2);
    margin-right: 0px;
  }
}
/* 2 columns */
@media (min-width: 1201px) and (max-width: 1760px) {
  .header-customer-id {
    padding-left: calc((100vw - 1120px - 80px) / 2);
  }
  .headerContainer {
    padding-right: calc((100vw - 1120px - 80px) / 2);
    margin-right: 0px;
  }
}
/* 1 columns */
@media (max-width: 1200px) {
  .header-customer-id {
    padding-left: 20px;
  }
  .headerContainer {
    padding-right: 20px;
    margin-right: 0px;
  }
}