.popoutContainer {
    padding: 10px 20px;
}

.popoutHeader {
    text-align: center;
    user-select: none;
    font-size: 32px;
}
.popoutSearch {
    float: left;
    margin-bottom: 25px;
}
.popoutExport {
    float: right;
    margin-bottom: 25px;
}

.exportCSVButton {
    margin-top: 10px;
    color: var(--cse-text);
    background-color: var(--cse-card-bg);
    border: 2px solid var(--cse-card-bg-alt);
    display: flex;
    justify-content: center;
    text-align: center;
    width: 200px;
    float: right;
}

.exportCSVButton :focus,
a:hover.exportCSVButton {
    text-decoration: none;
    color: var(--cse-text);
}

.popoutInput {
    min-width: 100px;
    align-self: center;
    height: 45px !important;
    max-width: 300px;
}

.popoutDisabled {
    opacity: 0.5 !important;
}

.popoutDataTable {
    margin-top: 25px;
}

.popoutSUGraph {
    max-height: 25vh;
    margin-bottom: 10vh;
}

.popoutSRGraph {
    margin-top: 25px;
    height: 50vh
}

/* ServiceRequests */
.serviceDeskHeaderItem {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-content: center;
    padding-bottom: 10px;
}

.serviceDeskContainer {
    display: flex;
}

.serviceDeskItem {
    padding-left: 5px;
    padding-right: 5px;
}

@media (max-width: 1434px) {
    .serviceDeskContainer {
        flex-direction: column;
    }

    .serviceDeskItem {
        width: 99%;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@media (min-width: 1435px) {
    .serviceDeskContainer {
        flex-direction: row;
    }

    .serviceDeskItem {
        width: 99%;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.serviceDeskExclusion {
    margin: 0;
}

.serviceDeskCard {
    text-align: center;
    margin: 5px;
    border: 1px solid var(--cse-card-border);
    border-radius: 3px 3px 0px 0px;
    padding: 0.5rem 1rem;
    min-height: 66px;
    box-shadow: 0.10em 0.30em 0.2em var(--cse-card-border);
    background-color: var(--cse-card-bg);
    font-size: 22px;
}