/* General */
.popoutContainer {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    user-select: none;
}
.popoutMain {
    text-align: center;
}
.popoutDividerNoText {
  text-align: center;
  display: flex;
  font-size: 24px;
  background-color: var(--cse-primary);
  align-self: center;
  flex: 1 1 auto;
  height: 0.063rem;
  margin: 10px 0;
}
.popoutDivider {
    text-align: center;
    display: flex;
    font-size: 24px;
    margin: 10px 0;
}
.popoutDivider::before {
    content: "";
    background-color: var(--cse-primary);
    align-self: center;
    height: 0.063rem;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    opacity: .6;
}
.popoutDivider::after {
    content: "";
    background-color: var(--cse-primary);
    align-self: center;
    height: 0.063rem;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    opacity: .6;
}
.popoutHeader {
    text-align: center;
    padding-right: 20px;
    margin-bottom: 0;
}
.popoutItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
/* Dropdown */
.selectDropDown {
    display: block;
    width: auto;
    max-width: 500px;
    height: 45px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .selectDropDown {
      transition: none;
    }
  }
  .selectDropDown::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  .selectDropDown:focus {
    color: #495057;
    background-color: #fff;
    border-color: #866ec9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 50, 134, 0.25);
  }
  .selectDropDown::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  .selectDropDown::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  .selectDropDown:disabled, .selectDropDown[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  .buttonSep {
    margin-right: 10px;
  }
  .errorText {
    color: var(--cse-neg);
    text-align: center;
  }