.container {
    width: 100%;
    max-width: 90vw;
    height: 95%;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.contentsContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}
.returnToContents {
    height: 5%;
    width: 100%;
}
.header {
    text-align: center;
}
.button {
    max-width: 40vw;
    margin: 10px 0;
}