body {
}

.error-container {
    background-color: var(--cse-primary-alt);
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
    .error-container h1 {
        color: #dc3545;
        font-size: 50px;
    }
