.addCommentForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.addCommentForm h2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.addCommentTextArea {
  background: var(--cse-bg);
  color: var(--cse-text);
  border: 1px solid var(--cse-card-bg-alt);
  border-radius: 0.25rem;
  font-size: 18px;
  min-width: 60%;
  height: 31px;
  outline: 1px solid var(--cse-card-bg-alt);
  overflow: hidden;
  resize: none;
}
.formTextInput {
  width: 60vw;
  color: var(--cse-text);
  background-color: var(--cse-bg);
  border: 1px solid var(--cse-bg-alt);
  border-radius: 0.25rem;
  max-width: 2100px;
}
.formTextInput:focus {
  border-color: var(--cse-primary);
}
.addCommentButton {
  margin-top: 2rem;
}
.cancelCommentButton {
  margin-left: 1rem;
}