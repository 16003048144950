.button {
  text-align: center;
  color: var(--cse-text);
  background-color: var(--cse-card-bg);
  border: 2px solid var(--cse-card-bg-alt);
  user-select: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.button:hover {
  cursor: pointer;
  background-color: var(--cse-card-bg-alt);
}
.button:disabled {
  background-color: darkgrey;
  color: dimgrey;
  border: 2px solid dimgray;
  cursor: auto;
}
.button:disabled:hover {
  background-color: darkgrey;
  color: dimgrey;
  border: 2px solid dimgray;
  cursor: auto;
}

.buttonPrimary {
  color: #fff;
  background-color: var(--cse-primary);
  border: 2px solid var(--cse-primary-alt)
}
.buttonPrimary:hover {
  background-color: var(--cse-primary-alt);
}