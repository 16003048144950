.skip-nav{
    background: var(--cse-bg);
    color: var(--cse-text);
    position: absolute;
    transform: translateY(-120%); 
    transition: transform 325ms ease-in;
    padding-left: 5.25rem;
    outline: none;
    z-index: 10;
}

.skip-nav:focus{
    transform: translateY(0);
}

.skip-nav:hover {
    color: var(--cse-text-alt);
}