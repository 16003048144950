.popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1007;
}
.popupBlackout {
    background: #00000090;
}
.popupBox {
    position: relative;
    width: 90%;
    margin: 0 auto;
    height: 80vh;
    margin-top: calc(100vh - 90vh);
    background: var(--cse-bg);
    color: var(--cse-text);
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    max-width: 2360px;
}
.popupOverflowDisable {
    overflow: hidden;
}
.closeIcon {
    content: 'X';
    cursor: pointer;
    position: relative;
    left: calc(100% - 15px);
    background: var(--cse-bg);
    color: var(--cse-text);
    line-height: 20px;
    text-align: center;
    border: 1px outset var(--cse-bg-alt);
    font-size: 20px;
    z-index: 10;
    padding: 0px 5px;
    box-shadow: var(--cse-box-shadow) 0px 5px 15px;
    -webkit-box-shadow: var(--cse-box-shadow) 0px 5px 15px;
    -moz-box-shadow: var(--cse-box-shadow) 0px 5px 15px;
    border-radius: 4px;
}
