.popoutSpinnerMain {
  position: relative;
}
.popoutSpinnerMain div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 250px;
  height: 250px;
  margin: 8px;
  border: 8px solid var(--cse-primary);
  border-radius: 50%;
  animation: popoutSpinner-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--cse-primary) transparent transparent transparent;
}
.popoutSpinnerMain div:nth-child(1) {
  animation-delay: -0.45s;
}

.popoutSpinnerMain div:nth-child(2) {
  animation-delay: -0.3s;
}

.popoutSpinnerMain div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes popoutSpinner-ring {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}
.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}