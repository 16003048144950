h1 {
    text-align: center;
}

.btn {
    padding-top: 25px;
}

:root {
    --input-padding-x: .75rem;
    --input-padding-y: .65rem;
}

.row {
    padding-bottom: 15px;
}

label {
    padding: 0%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form-floating-labels {
    position: relative;
    width: 100%;
}

.form-control {
    height: 45px;
}

.form-floating-labels > input,
.form-floating-labels > label {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-floating-labels > input,
.form-floating-labels > input::after,
.form-floating-labels > input:hover {
    color: var(--cse-text) !important;
    background-color: var(--cse-bg) !important;
}

.form-floating-labels > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: var(--cse-text-alt);
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all 0.1s ease-in-out;
    pointer-events: none;
}

.form-floating-labels input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-floating-labels input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: var(--cse-text-alt);
}
