.light-theme {
    --cse-bg: #ffffff;
    --cse-bg-alt: #bdbdbd;
	--cse-text: #000;
	--cse-text-alt: #777777;
    --cse-text-disabled: #8b8a8a5d;
	--cse-primary: #483287;
    --cse-primary-alt: #6646c0;
    --cse-pos: #31c024;
	--cse-warn: #ffbb00;
	--cse-neg: #f12a2a;
    --cse-box-shadow: #00000065;
    --cse-box-shadow-inverse: #ffffff65;
    /* Card */
    --cse-card-bg: #f5f5f5;
    --cse-card-bg-alt: #d3d2d2;
	--cse-card-border: #b8b7b7;
    /* Icon */
    --cse-icon-filter: invert(100%) sepia(0%) saturate(7485%) hue-rotate(214deg) brightness(102%) contrast(105%);
}

.dark-theme {
    --cse-bg: #2c2c2c;
    --cse-bg-alt: #5f5f5f;
    --cse-text: #ffffff;
    --cse-text-alt: #999999;
    --cse-text-disabled: #2424247a;
    --cse-primary: #483287;
    --cse-primary-alt: #3c2872;
    --cse-pos: #31c024;
    --cse-warn: #f2ce2c;
    --cse-neg: #f12a2a;
    --cse-box-shadow: #ffffff65;
    --cse-box-shadow-inverse: #00000065;
    /* Card */
    --cse-card-bg: #363636;
    --cse-card-bg-alt: #5f5e5e;
    --cse-card-border: #2c2c2c;
    /* Icon */
    --cse-icon-filter: invert(0%) sepia(0%) saturate(7465%) hue-rotate(338deg) brightness(91%) contrast(109%);
}

html {
    width: 100%;
}

html, body,
.light-theme,
.dark-theme {
    background-color: var(--cse-bg);
    color: var(--cse-text);
    min-height: 100vh;
}
.content-container {
    display: flex;
}
.content {
    padding-top: 0.9375rem;
    min-width: 592px;
    min-height: calc(100vh - 25px);
    background-color: var(--cse-bg);
    width: 100%;
}
.content-margin {
    min-height: 100%;
    min-width: 80px;
    background-color: var(--cse-primary-alt)
}

.dash-card p {
    margin: 0 !important;
}

/* Small Screens */
@media only screen and (max-width: 599px) {
    .content {
        margin-left: 10px;
    }
    .content-margin {
        min-height: 0px;
        min-width: 0px;
    }
}

::-webkit-scrollbar {
    width: 0.25rem;
}
::-webkit-scrollbar-track {
    background-color: var(--cse-card-bg-alt);
}
::-webkit-scrollbar-thumb {
    background-color: var(--cse-primary);
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
  }
  @keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
  }