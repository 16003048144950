.mainText {
    user-select: none;
    font-size: 22px;
}

.chart {
    margin-bottom: 10px;
    padding-bottom: 10px;
    height: 120px;
    width: 45%;
    padding: 10px 0 0 0;
}