.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  min-height: 1px;
  max-width: calc(100vw - 80px);
  max-height: 100vh;
  overflow: hidden;
}
@media (min-width: 768px) {
  .container {
      width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
      width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
      width: 1170px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  min-height: 1px;
  max-width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  height: 100%;
  min-height: 1px;
  margin: auto;
  justify-content: center;
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}