
.footer {
  display: flex;
  flex-direction: row;
  padding-right: 0.5rem;
  user-select: none;
  background-color: var(--cse-bg);
  color: var(--cse-text);
  margin-bottom: 0;
}
.marqueeText {
  margin-left: 10px;
  width: 50%;
  margin-bottom: 0;
}
.copyrightText {
  width: 100%;
  text-align: end;
}

@media only screen and (max-width: 599px) {
  .footer {
    padding-bottom: 64px;
  }
}