.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}
.layoutButton {
    margin-left: auto;
    margin-right: auto;
    margin: 5px auto 5px auto;
}