/* .update-header .update-version-num update-title .update-text */
div.cse-card, .cse-card>h1 {
    background: var(--cse-bg);
    color: var(--cse-text);
}

.update-header {
    text-align: left;
}

.cse-card{
    padding-top: 10px;
    padding-left: 5px;
    padding-bottom: 10px;
}

.cse-card>ul {
    text-align: left;
}

.update-header {
    font-size: 28px;
    margin-bottom: 25px;
}

.update-version-num {
    display: flex;
    font-size: 1rem;
}
.update-version-num::after {
    content: "";
    background-color: var(--cse-primary);
    align-self: center;
    height: 0.063rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    opacity: .6;
}
.update-version-num>sup{
    top: 0.3125rem;
    font-size: 60%;
}
.update-text-bold{
    font-weight: bold;
}
.pageButtonContainer {
    display: flex;
    justify-content: center;
}
.iconContainer {
    height: 45px;
    width: 45px;
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 15px;
}
.iconContainerDisabled {
    cursor: auto;
    opacity: 0.5;
}
.pageButton {
    filter: var(--cse-icon-filter);
    height: 100%;
    width: 100%;
}