.cse-nav {
    position: fixed;
    z-index: 1005;
    background-color: var(--cse-primary-alt);
    color: var(--cse-text);
}
.cse-navbar {
    display: flex;
    flex-direction: column;
    background-color: var(--cse-primary-alt);
    color: var(--cse-text);
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.cse-navbar::-webkit-scrollbar {
    width: 0.25rem;
}
.cse-navbar::-webkit-scrollbar-track {
    background-color: var(--cse-card-bg-alt);
}
.cse-navbar::-webkit-scrollbar-thumb {
    background-color: var(--cse-primary);
}

.cse-nav-menu-icon-container {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    width: 3.5rem;
    margin: 0.5rem auto 0.5rem auto;
    padding: 0.25rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    background-color: var(--cse-primary-alt);
    border-radius: 1rem;
    transition: background-color, border-radius 200ms ease-in -50ms;
}
.cse-nav-menu-icon-container:hover {
    background-color: var(--cse-primary);
    border-radius: 0.5rem
}
.cse-nav-menu-icon-container span {
    user-select: none;
}
.cse-nav-menu-icon-container a {
    height: 100%;
    width: 100%;
}

.cse-nav-menu-icon-container-empty {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    width: 3.5rem;
    margin: 0.5rem auto 0.5rem auto;
    padding: 0.25rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    background-color: var(--cse-primary-alt);
    border-radius: 1rem;
    cursor: auto !important;
    transition: background-color, border-radius 200ms ease-in -50ms;
}
.cse-nav-menu-icon-container-empty:hover {
    border-radius: 0.5rem
}
.cse-nav-menu-icon-container-empty span {
    user-select: none;
}
.cse-nav-menu-icon-container-empty a {
    height: 100%;
    width: 100%;
}
.cse-nav-menu-icon-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    max-width: 3.25rem;
    animation: 0.25s ease-out 0s 1 fadeInAnimation;
}

.cse-nav-menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    max-width: 3.25rem;
    cursor: pointer;
    animation: 0.25s ease-out 0s 1 fadeInAnimation;
}
.icon-hide-mobile {
    opacity: 1;
    visibility: visible;
    display: flex;
}

.cse-nav-menu-tooltip {
    position: absolute; 
    background-color: #111827; 
    transition-property: all; 
    transition-duration: 100ms; 
    --transform-scale-x: 0;
    --transform-scale-y: 0; 
    color: #ffffff; 
    font-size: 0.75rem;
    line-height: 1rem; 
    font-weight: 700; 
    width: auto; 
    min-width: max-content; 
    border-radius: 0.275rem; 
    padding: 0.25rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); 
    opacity: 0;
    transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -webkit-transition: opacity .5s ease-in-out;
    transform: translate(-110vh, 0px);
}
.cse-nav-menu-tooltip-hovered {
    transform: translate(0px, 0px);
    opacity: 1;
    transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
}

.cse-nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
}
.unread-changelog {
    position: relative;
    top: -20px;
    left: -5px;
    background-color: red;
    height: 10px;
    width: 10px;
    min-height: 10px;
    min-width: 10px;
    padding: 0;
    border-radius:50%;
    box-shadow: 0 1px 3px 0 var(--cse-box-shadow-inverse), 0 1px 2px -1px var(--cse-box-shadow-inverse);
}
/* Small Screens */
@media only screen and (max-height:545px) {
    .unread-changelog {
        height: 5px;
        width: 5px;
        min-height: 5px;
        min-width: 5px;
        top: -15px;
    }
}
@media only screen and (max-height:470px) {
    .unread-changelog {
        top: -10px;
    }
}
@media only screen and (max-height:390px) {
    .unread-changelog {
        top: -7px; 
        left: -3px;
    }
}
@media only screen and (max-width: 599px) {
    .cse-navbar {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4rem;
        flex-direction: row;
        min-width: 400px;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .cse-nav-menu-tooltip {
        bottom: 4rem;
    }
    .icon-hide-mobile {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
}
/* Large Screens */
@media only screen and (min-width: 600px) {
    .cse-navbar {
        height: 100vh;
        width: 5rem;
        top: 0;
    }
    .cse-nav-menu-tooltip {
        left: 5rem; 
        padding: 0.5rem; 
    }
    div.cse-nav-menu-last-item ,img.cse-nav-menu-last-item {
        margin-top: auto;
        margin-bottom: 0.5rem;
    }
}